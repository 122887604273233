import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import StepOne from "../newRegistration/stepOne";

const EditPersonal = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <StepOne pageType="edit" {...props} />
    </>
  );
};

export default EditPersonal;
