import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { ACADEMIC_YEAR } from "../../utils";
import { ServiceUrl } from "../../utils/serviceUrl";
import Axios from "axios";

const SignUpLink = (props) => {
  const context = useContext(PsContext);

  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    loadIp();
  }, []);

  const loadIp = () => {
    return new Promise((resolve, reject) => {
      let url = ServiceUrl.ADMISSION.IP;

      Axios.post(url)
        .then(function ({ data }) {
          // console.log(data);
          setIpAddress(data.message);
          resolve(data.message);
        })
        .catch((error) => {
          console.error("Error loading IP:", error);
          reject([]);
        });
    });
  };
  // console.log(ipAddress);
  // console.log(context.settingValue("enable_ug_admission"));

  const isUgAdmissionEnabled =
    context.settingValue("enable_ug_admission") === "1" ||
    context.settingValue("enable_ug_admission_ip") === ipAddress;

  const isPgAdmissionEnabled =
    context.settingValue("enable_pg_admission") === "1" ||
    context.settingValue("enable_pg_admission_ip") === ipAddress;

  const isMphilAdmissionEnabled =
    context.settingValue("enable_mphil_admission") === "1" ||
    context.settingValue("enable_mphil_admission_ip") === ipAddress;

  return (
    <>
      <Card className="rounded shadow card-border-btm">
        <Card.Header className="fw-bold text-center">
          Online admission - {ACADEMIC_YEAR}
        </Card.Header>
        <Card.Body>
          <ul className="list-group list-group-flush fs-6">
            {isUgAdmissionEnabled && (
              <li className="list-group-item my-2">
                <Link
                  to="/instruction/ug-admission-instruction"
                  className="text-dark fw-bold"
                >
                  Apply for UG Program
                  <i className="fa-solid fa-chevron-right float-end"></i>
                </Link>
              </li>
            )}
            {isPgAdmissionEnabled && (
              <li className="list-group-item my-2">
                <Link
                  to="/instruction/pg-admission-instruction"
                  className="text-dark fw-bold"
                >
                  Apply for PG
                  <i className="fa-solid fa-chevron-right float-end"></i>
                </Link>
              </li>
            )}
            {isMphilAdmissionEnabled && (
              <li className="list-group-item my-2">
                <Link
                  to="/instruction/mphil-admission-instruction"
                  className="text-dark fw-bold"
                >
                  Apply for MPhil
                  <i className="fa-solid fa-chevron-right float-end"></i>
                </Link>
              </li>
            )}
            <li className="list-group-item my-2">
              <Link to="/page/prospectus" className="text-dark fw-bold">
                Prospectus
                <i className="fa-solid fa-chevron-right float-end"></i>
              </Link>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </>
  );
};

export default SignUpLink;
