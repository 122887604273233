import { Button, Dialog, List } from "antd-mobile";
import $ from "jquery";
import React, { useState, useContext, useEffect } from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import Header from "./header";
import Footer from "./footer";
import Dashboard from "../dashboard";
import PgEdit from "../pgEdit";
import CourseApply from "../courseApply";
import PaymentStatus from "../paymentStatus";
import MyProfile from "../myProfile";

const PgAdmissionLayout = (props) => {
  const context = useContext(PsContext);

  useEffect(() => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }, [props.location.pathname]);

  return (
    <>
      <Header path={props.location.pathname || null} {...props} />
      <div className="inner-wrapper ">
        <section role="main " className="page-content">
          <Switch>
            <Route path="/edit/:type" component={PgEdit} exact />
            <Route path="/apply" component={CourseApply} exact />
            <Route path="/my-profile" component={MyProfile} exact />
            <Route
              path="/payment-status/:uuid"
              component={PaymentStatus}
              exact
            />
            <Route path="/" component={Dashboard} />
          </Switch>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default withRouter(PgAdmissionLayout);
