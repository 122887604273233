import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import LoginForm from "./loginForm";

const Login = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <div className="container mt-4 mb-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <LoginForm />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(Login);
