import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import StepTwo from "../newRegistration/stepTwo";

const EditMarks = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <StepTwo pageType="edit" {...props} />
    </>
  );
};

export default EditMarks;
