import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { Col, Row } from "react-bootstrap";
import { siteUrl } from "../../../utils";
import { VENDOR_LOGO } from "../../../utils/data";

const Footer = (props) => {
  const context = useContext(PsContext);

  const policies = [
    { text: "Terms and conditions", path: "/page/terms-and-condition" },
    { text: "Privacy policy", path: "/page/privacy-policy" },
    { text: "Refund policy", path: "/page/refund-policy" },
    { text: "Cancellation policy", path: "/page/cancellation-policy" },
  ];

  return (
    <>
      <footer className="py-4">
        <div className="container">
          <div className=" text-white">
            <Row>
              <Col md={8}>
                Copyright - {context.settingValue("admission_form_clg_name")}{" "}
                &copy; All rights reserved
              </Col>
              <Col md={4} className="text-end">
                Developed by{" "}
                <a href="https://peacesoft.in" target="_blank">
                  Peace Soft
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
