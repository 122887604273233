import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import { capitalizeFirst } from "../../../utils";

const CommunicationDetails = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  return (
    <>
      <InputField
        label="Door No / Street"
        value={capitalizeFirst(field("street"))}
      />

      <InputField label="Place" value={capitalizeFirst(field("place"))} />

      <InputField
        label="Village / City"
        value={capitalizeFirst(field("city"))}
      />

      <InputField label="Taluk" value={capitalizeFirst(field("taluk"))} />

      <InputField label="District" value={capitalizeFirst(field("district"))} />

      <InputField label="Country" value={capitalizeFirst(field("country"))} />

      <InputField label="State" value={capitalizeFirst(field("state"))} />

      <InputField label="Pincode" value={capitalizeFirst(field("pincode"))} />
    </>
  );
};

export default CommunicationDetails;
