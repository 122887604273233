import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";

import { withRouter } from "react-router-dom";

import PsContext from "../../context";
import WebLayout from "./webLayout";
import { upperCase } from "../../utils";
import UgAdmissionLayout from "../ugAdmission/ugAdmissionLayout";
import PgAdmissionLayout from "../pgAdmission/pgAdmissionLayout";
const Web = (props) => {
  const context = useContext(PsContext);

  useEffect(() => {
    context.loadSettings();
  }, []);

if (context.logged == "yes") {
    if (upperCase(context.user.applied_for) == "UG") {
      return (
        <section className="">
          <UgAdmissionLayout {...props} />
        </section>
      );
    } else if (upperCase(context.user.applied_for) == "PG") {
      return (
        <section className="">
          <PgAdmissionLayout {...props} />
        </section>
      );
    } else return <section className=""></section>;
  }
  return (
    <section className="">
      <WebLayout {...props} />
    </section>
  );
};

export default withRouter(Web);
