import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import {
  baseUrl,
  capitalizeFirst,
  lowerCase,
  momentDate,
  upperCase,
} from "../../../utils";
import { ServiceUrl } from "../../../utils/serviceUrl";

const Personal = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <InputField
            label="Name"
            value={field("name") + " " + field("initial")}
            firstMd={4}
            secondMd={8}
          />
          <InputField
            label="Gender"
            value={capitalizeFirst(field("gender"))}
            firstMd={4}
            secondMd={8}
          />

          <InputField
            label="Date of Birth"
            value={momentDate(field("date_of_birth"), "DD/MMM/YYYY")}
            firstMd={4}
            secondMd={8}
          />

          <InputField
            label="Marital Status"
            value={upperCase(field("marital_status"))}
            firstMd={4}
            secondMd={8}
          />
        </Col>

        <Col md={3}>
          <img
            src={
              baseUrl +
              ServiceUrl.PG.VIEW_CERTIFICATE +
              "?api_code=" +
              field("api_code") +
              "&field_name=profile_photo"
            }
            className="border"
            style={{
              maxWidth: "100%",
              maxHeight: "180px",
            }}
          />
        </Col>
      </Row>

      <InputField label="Aadhar No" value={field("aadhar")} />

      <InputField label="ABC Id" value={field("abc_id")} />

      <InputField
        label="Community / Caste"
        value={
          upperCase(field("community")) +
          " - " +
          capitalizeFirst(field("caste"))
        }
      />

      <InputField
        label="Religion"
        value={
          lowerCase(field("religion")) == "christian"
            ? `${capitalizeFirst(field("religion"))} / ${field("denomination")}`
            : capitalizeFirst(field("religion"))
        }
      />

      <InputField label="Blood Group" value={upperCase(field("blood_group"))} />

      <InputField
        label="Mother Tongue"
        value={capitalizeFirst(field("mother_tongue"))}
      />

      <InputField
        label="Nationality"
        value={capitalizeFirst(field("nationality"))}
      />
    </>
  );
};

export default Personal;
