import { Button, Dialog, List } from "antd-mobile";
import $ from "jquery";
import React, { useState, useContext, useEffect } from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import Header from "./header";
import Footer from "./footer";
import webPages from "../webPages";
import admissionInstructions from "../../admissionInstructions";
import login from "../../login";
import signUp from "../../signUp";
import homePage from "../homePage";
import Registration from "../../registration";
import MobileVerification from "../../mobileVerification";

const WebLayout = (props) => {
  const context = useContext(PsContext);

  useEffect(() => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }, [props.location.pathname]);

  return (
    <>
      <Header {...props} />
      <div className="inner-wrapper ">
        <section role="main " className="page-content">
          <Switch>
            <Route path="/apply/:courseType" component={Registration} />
            <Route path="/page/:pageId" component={webPages} />
            <Route
              path="/instruction/:pageId"
              component={admissionInstructions}
            />

            <Route path="/sign-in" component={login} exact />
            <Route path="/sign-up" component={signUp} exact />
            <Route
              path="/mobile-verification"
              component={MobileVerification}
              exact
            />
            <Route path="/" component={homePage} exact />
            <Route path="*" component={homePage} />
          </Switch>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default withRouter(WebLayout);
