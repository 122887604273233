export const ServiceUrl = {
  ADMISSION: {
    SETTINGS: "v1/admission/settings",
    IP: "v1/admission/ip",
    ANNOUNCEMENTS: "v1/announcement",
    DISABILITY_LIST: "v1/disability_list",
    CUSTOM_PAGE: "v1/page",
    CAPTCHA: "v1/captcha",
    LOGIN: "v1/applicant/login",
    REGISTER: "v1/register",
    CHECK_MOBILE_VERIFIED: "v1/mobile_verified",
    RESEND_OTP: "v1/mobileotp/resend",
    VERIFY_MOBILE_OTP: "v1/mobileotp/verify",
  },
  UG: {
    APPLICANT_MIN_INFO: "v1/applicant/min_info",
    STEP_ONE: "v1/ug/register/step_one",
    HSC_SUBJECTS: "v1/hsc/subjects",
    STEP_TWO: "v1/ug/register/hscmarksupload",
    UPLOAD_FILE: "v1/ug/file_upload",
    CLOSE_FILE_UPLOAD: "v1/ug/close_upload",
    PERSONAL_MARKS_PREVIEW: "v1/ug/personal_marks_preview",
    COURSE_FOR_ME: "v1/ug/apply/course_for_me",
    COURSE_APPLY: "v1/ug/course/apply",

    VIEW_CERTIFICATE: "v1/ug/application/certificate",
    APPLIED_COURSES: "v1/ug/courses",

    UPDATE_STEP_ONE: "v1/ug/update/personal",
    UPDATE_STEP_TWO: "v1/ug/update/marks",
    UPDATE_DECLARATION: "v1/ug/update/declaration",

    APPLIED_ORDER_DETAILS: "v1/ug/orders",
    REPAY_LINK: "v1/ug/course/repay",

    APPLICATION_DOWNLOAD_LINK: "v1/ug/pdf/",
    TRACK_MY_ORDER: "v1/ug/trackmyorder",
  },

  PG: {
    APPLICANT_MIN_INFO: "v1/pgapplicant/min_info",
    STEP_ONE: "v1/pg/register/step_one",
    PERSONAL_MARKS_PREVIEW: "v1/pg/personal_marks_preview",
    UG_FOR_PG: "v1/pg/get_ug_forpg",
    STEP_TWO: "v1/pg/register/ugmarksupload",
    UPLOAD_FILE: "v1/pg/file_upload",
    CLOSE_FILE_UPLOAD: "v1/pg/close_upload",
    COURSE_FOR_ME: "v1/pg/apply/course_for_me",
    COURSE_APPLY: "v1/pg/course/apply",

    VIEW_CERTIFICATE: "v1/pg/application/certificate",
    APPLIED_COURSES: "v1/pg/courses",

    UPDATE_STEP_ONE: "v1/pg/update/personal",
    UPDATE_STEP_TWO: "v1/pg/update/marks",

    APPLIED_ORDER_DETAILS: "v1/pg/orders",
    REPAY_LINK: "v1/pg/course/repay",
    APPLICATION_DOWNLOAD_LINK: "v1/pg/pdf/",
  },
};
