import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField2 from "./inputField2";
import PsContext from "../../../context";
import InputField from "./inputField";
import { upperCase } from "../../../utils";

const SpecialReservation = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  return (
    <>
      <InputField2
        label="Whether the applicant is differently abled ?"
        value={field("physically_challanged") == "1" ? "Yes" : "No"}
      />

      {field("physically_challanged") == "1" && (
        <>
          <InputField
            label="Disability Code"
            value={upperCase(field("disability_code"))}
          />
          <InputField
            label="Disability Percentage"
            value={upperCase(field("disability_percentage"))}
          />
          <InputField
            label="District Name"
            value={upperCase(field("disablity_district"))}
          />
          <InputField
            label="Disability Card No"
            value={upperCase(field("disability_card_no"))}
          />
          <hr />
        </>
      )}
      <InputField2
        label="Whether the applicant is child of Ex-Serviceman ?"
        value={field("ex_army_child") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Whether the applicant is migrated from Jammu and
		Kashmir ?"
        value={field("citizen_from_jammu") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Whether the applicant is Tamil Origin from Andaman
		Nicobar islands ?"
        value={field("srilankan_citizen") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Whether the applicant is belongs to Other State or NRI
		?"
        value={field("is_nri") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Participation in Sports ?"
        value={field("in_sports") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Participation in NCC ?"
        value={field("in_ncc") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Participation in NSS ?"
        value={field("in_nss") == "1" ? "Yes" : "No"}
      />
    </>
  );
};

export default SpecialReservation;
